/** ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

/** 
  All of the routes for the Material Kit 2 PRO React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// next
import dynamic from "next/dynamic";

// MUI Icons
const CollectionsRounded = dynamic(() => import("@mui/icons-material/CollectionsRounded"), { loading: () => null });
const CottageRounded = dynamic(() => import("@mui/icons-material/CottageRounded"), { loading: () => null });
const DashboardRounded = dynamic(() => import("@mui/icons-material/DashboardRounded"), { loading: () => null });
const GrassRounded = dynamic(() => import("@mui/icons-material/GrassRounded"), { loading: () => null });
const NewspaperRounded = dynamic(() => import("@mui/icons-material/NewspaperRounded"), { loading: () => null });
const OutdoorGrillRounded = dynamic(() => import("@mui/icons-material/OutdoorGrillRounded"), { loading: () => null });
const PeopleRounded = dynamic(() => import("@mui/icons-material/PeopleRounded"), { loading: () => null });
const ReviewsRounded = dynamic(() => import("@mui/icons-material/ReviewsRounded"), { loading: () => null });
const WavesRounded = dynamic(() => import("@mui/icons-material/WavesRounded"), { loading: () => null });

const routes = [
  {
    name: "Patio Builder",
    icon: <DashboardRounded />,
    route: "/patio-configurator",
    collapse: false,
  },
  {
    name: "Our Work",
    icon: <CollectionsRounded />,
    route: "/our-work",
    collapse: false,
  },
  {
    name: "Features",
    icon: <WavesRounded />,
    columns: 3,
    rowsPerColumn: 1,
    collapse: [
      {
        name: "Outdoor Living",
        icon: <OutdoorGrillRounded />,
        collapse: [
          {
            name: "Pools",
            icon: "waves",
            route: "/our-work/feature/pools",
          },
          {
            name: "Patios",
            route: "/our-work/feature/patios",
          },
          {
            name: "Outdoor Kitchens",
            route: "/our-work/feature/outdoor-kitchens",
          },
          {
            name: "Fireplaces",
            route: "/our-work/feature/fireplaces",
          },
          {
            name: "Fire Pits",
            route: "/our-work/feature/firepits",
          },
          {
            name: "Ponds and Waterfalls",
            route: "/our-work/feature/ponds-and-waterfalls",
          },
        ],
      },
      {
        name: "Curb Appeal",
        icon: <CottageRounded />,
        collapse: [
          {
            name: "Driveways",
            route: "/our-work/feature/driveways",
          },
          {
            name: "Entrways",
            route: "/our-work/feature/entryways",
          },
          {
            name: "Walkways",
            route: "/our-work/feature/walkways",
          },
        ],
      },
      {
        name: "Everywhere",
        icon: <GrassRounded />,
        collapse: [
          {
            name: "Retaining Walls",
            route: `/our-work/feature/retaining-walls`,
          },
          {
            name: "Plantings",
            route: "/our-work/feature/plantings",
          },
          {
            name: "Landscape Lighting",
            route: "/our-work/feature/lighting",
          },
        ],
      },
    ],
  },
  {
    name: "Reviews",
    icon: <ReviewsRounded />,
    route: "/reviews",
    collapse: false,
  },
  {
    name: "About",
    icon: <PeopleRounded />,
    route: "/about-us",
    collapse: false,
  },
  {
    name: "Articles",
    icon: <NewspaperRounded />,
    route: "/articles",
    collapse: false,
  },
];

export default routes;
