/* eslint-disable no-param-reassign */
/**
=========================================================
* NextJS Material Dashboard 2 PRO TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react
import { useState } from "react";
import { useMaterialUIController } from "/context";

// next
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import Image from "next/image";
import Link from "next/link";

// prop-types
import PropTypes from "prop-types";

// logo
import logo from "/assets/img/logo-white.png";

// routes
import routes from "/routes";

// MUI
import MDBox from "/components/MDBox";

// MUI
const Container = dynamic(() => import("@mui/material/Container"), { loading: () => null });

// MUI Icons
const CloseRounded = dynamic(() => import('@mui/icons-material/CloseRounded'), { loading: () => null });
const EmailRounded = dynamic(() => import('@mui/icons-material/EmailRounded'), { loading: () => null });
const LocationOnRounded = dynamic(() => import('@mui/icons-material/LocationOnRounded'), { loading: () => null });
const MenuRounded = dynamic(() => import('@mui/icons-material/MenuRounded'), { loading: () => null });
const PhoneEnabledRounded = dynamic(() => import('@mui/icons-material/PhoneEnabledRounded'), { loading: () => null });

// PRO
const MDButton = dynamic(() => import('/components/MDButton'), { loading: () => null });
const MDTypography = dynamic(() => import('/components/MDTypography'), { loading: () => null });

// Parts
const DefaultNavbarDropdown = dynamic(() => import('./DefaultNavbarDropdown'), { loading: () => null });
const DefaultNavbarMobile = dynamic(() => import('./DefaultNavbarMobile'), { loading: () => null });
const DesktopPopperMenu = dynamic(() => import('./DesktopPopperMenu'), { loading: () => null });

function DefaultNavbar({ transparent, light, darkPreHeader }) {
  const [controller] = useMaterialUIController();
  const [dropdownEl, setDropdownEl] = useState(null);
  const [dropdownName, setDropdownName] = useState("");
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const { pathname } = useRouter();

  return (
    <>
      <Container>
        <MDBox
          py={1}
          px={{ xs: 1, sm: transparent ? 0 : 3, lg: transparent ? 0 : 2 }}
          my={3}
          mx={3}
          width="calc(100% - 48px)"
          borderRadius="lg"
          shadow={transparent ? "none" : "md"}
          color={light ? "white" : "dark"}
          position="absolute"
          left={0}
          zIndex={99}
          sx={({
            palette: { transparent: transparentColor, white, background },
            functions: { rgba },
          }) => ({
            backgroundColor: transparent
              ? transparentColor.main
              : rgba(controller.darkMode ? background.sidenav : white.main, 0.8),
            backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
          })}
        >
          <MDBox sx={{ textAlign: controller.mobileView === false ? "right" : "center" }} mt={-4.2} mb={{ xs: 0.5, lg: -1 }}>
            <MDButton
              href="tel:1-631-424-5544"
              startIcon={<PhoneEnabledRounded color="success" />}
              variant="text"
              color={darkPreHeader && !controller.darkMode ? "dark" : "white"}
              fontWeight={300}
              size={controller.mobileView ? "small" : "medium"}>
              {controller.mobileView === false ? "(631) 424-5544" : "Call"}
            </MDButton>
            <Link href="/contact#message">
              <MDButton
                startIcon={<EmailRounded fontSize="large" color="info" />}
                variant="text"
                color={darkPreHeader && !controller.darkMode ? "dark" : "white"}
                fontWeight={300}
                size={controller.mobileView ? "small" : "medium"}
              >
                message
              </MDButton>
            </Link>
            <MDButton
              href="https://www.google.com/maps/dir//Autumn+Leaf+Landscape+Design,+100+Centershore+Rd,+Centerport,+NY+11721/@40.8914884,-73.3773813,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89e826770c363025:0x99c88aad47373ab6!2m2!1d-73.3743343!2d40.8922589"
              target="_blank"
              rel="noreferrer noopener"
              startIcon={<LocationOnRounded fontSize="large" color="warning" />}
              variant="text" color={darkPreHeader && !controller.darkMode ? "dark" : "white"}
              fontWeight={300}
              size={controller.mobileView ? "small" : "medium"}
            >
              {controller.mobileView == false && "100 Centershore Rd, Centerport, NY 11721"}
              {controller.mobileView === true && "Location"}
            </MDButton>
          </MDBox>
          <MDBox display="flex" justifyContent="space-between" alignItems="center">
            <Link href="/">
              <MDBox py={transparent ? 1.5 : 0.75} lineHeight={1} pl={{ xs: 0, lg: 1 }} display="flex" alignItems="center">
                <MDBox mr={0.5}>
                  <Image src={logo.src} width={controller.mobileView ? 40 : 60} height={controller.mobileView ? 40 : 60} alt="Autumn Leaf" />
                </MDBox>
                <MDTypography variant="button" fontWeight="bold" color={light ? "white" : "dark"}>
                  Autumn Leaf Landscape
                </MDTypography>
              </MDBox>
            </Link>
            {/* Desktop */}
            {pathname !== "/patio-configurator" && (
              <>
                <MDBox color="inherit" display={{ xs: "none", lg: "flex" }} m={0} p={0} justifyContent="flex-end" flexGrow="1" alignItems="center">
                  {routes.map(({ name, icon, href, route, collapse }) => {
                    return (
                      <DefaultNavbarDropdown
                        key={name}
                        name={name}
                        icon={icon}
                        href={href}
                        route={route}
                        collapse={Boolean(collapse)}
                        onMouseEnter={({ currentTarget }) => {
                          if (collapse) {
                            setDropdownEl(currentTarget);
                            setDropdownName(name);
                          }
                        }}
                        onMouseLeave={() => collapse && setDropdownEl(null)}
                        light={light}
                      />
                    );
                  },
                  )}
                </MDBox>
                <MDBox display={{ xs: "none", lg: "inline-block" }}>
                  <Link href="/contact">
                    <MDButton variant="gradient" color={controller.sidenavColor} size="small">
                      contact
                    </MDButton>
                  </Link>
                </MDBox>
              </>
            )}
            {/* toggle */}
            <MDBox
              display={{ xs: "inline-block", lg: pathname !== "/patio-configurator" ? "none" : "inline-block" }}
              lineHeight={0}
              py={1.5}
              pl={1.5}
              color={transparent ? "white" : "inherit"}
              sx={{ cursor: "pointer" }}
              onClick={() => setMobileNavbar(!mobileNavbar)}
            >
              {mobileNavbar ? <CloseRounded fontSize="default" /> : <MenuRounded fontSize="default" />}
            </MDBox>
          </MDBox>
          <MDBox display={{ xs: "block", lg: pathname !== "/patio-configurator" ? "none" : "block" }} bgColor={controller.darkMode ? "dark" : "white"} shadow={transparent ? "lg" : "none"} borderRadius="md" px={transparent ? 2 : 0}
            sx={{
              ...(pathname === '/patio-configurator' && { maxWidth: 400, marginLeft: "auto", marginRight: 0 }),
            }}
          >
            <DefaultNavbarMobile open={mobileNavbar} routes={routes} />
          </MDBox>
        </MDBox>
      </Container>
      {dropdownEl !== null && (
        <DesktopPopperMenu
          dropdownEl={dropdownEl}
          dropdownName={dropdownName}
          setDropdownEl={setDropdownEl}
          setDropdownName={setDropdownName}
        />
      )}
    </>
  );
}

// Declaring default props for DefaultNavbar
DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
};

export default DefaultNavbar;
